import React from 'react';

import Layout from '../components/shared/layout';
import SEO from '../components/shared/seo';

export default () => (
	<Layout>
		<SEO title="404" />
		<h1>404</h1>
		<p>The page you are looking for does not exist.</p>
	</Layout>
);
